import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiErrorResponse, ApiResponse, ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public signin: any = {
        email: null,
        password: null,
    };

    public pinSignin: any = {
        email: null,
        pin: null,
    };

    public redirect?: string;
    public error?: string;
    public loading = false;
    public azureLoading: boolean = false;
    public isPinLoginFormLoading: boolean = false;
    public isPinLoginFormVisible: boolean = false;

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        @Inject('UserService') private userService: UserServiceInterface,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private msalService: MsalService,
    ) {}

    public doSignin(): void {
        this.loading = true;
        this.api.post({ url: '/api/user/login_check', data: this.signin, skipAuth: true }).subscribe(
            (res: ApiResponse<any>) => {
                this.error = null;
                this.userService
                    .loginUser(res.data)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.userService.reloadRoute(this.redirect || '/');
                    });
            },
            (err: ApiErrorResponse<any>) => {
                this.loading = false;
                this.error = err.error?.error ? `${err.error.error.code} ${err.error.error.message}` : err.error?.message;
                this.cdRef.detectChanges();
            },
        );
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => (this.redirect = params.returnUrl || null));
        // Handle the redirect from Azure
        this.msalService.handleRedirectObservable().subscribe({
            next: (authResult: AuthenticationResult) => {
                // Check if the authentication result is available
                if (authResult) {
                    // Set Azure loading state to true
                    this.azureLoading = true;
                    this.cdRef.detectChanges();
                    this.checkAndSetActiveMicrosoftAccount();
                    this.checkMicrosoftUserIndentity();
                }
            },
            error: err => {
                // Set Azure loading state to false if there is an error
                this.azureLoading = false;
                this.error = err.error?.error ? `${err.error.error.code} ${err.error.error.message}` : err.error?.message;
                this.cdRef.detectChanges();
            },
        });
        this.userService.watch.pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
            if (user.loggedIn) this.userService.reloadRoute(this.redirect || '/');
        });
    }

    public doPinSignin(): void {
        // Set pinLoginFormLoading to true, indicating process has started
        this.isPinLoginFormLoading = true;
        this.api.post({ url: '/login/check-pin', data: this.pinSignin, skipAuth: true }).subscribe(
            (res: ApiResponse<any>) => {
                // Reset any previously set error messages to null
                this.error = null;
                this.cdRef.detectChanges();

                // Call the loginUser function with the data received from the response
                this.userService
                    .loginUser(res.data)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.userService.reloadRoute(this.redirect || '/');
                    });
            },
            (err: ApiErrorResponse<any>) => {
                // Set pinLoginFormLoading to false, indicating process has stopped
                this.isPinLoginFormLoading = false;
                this.error = err.error?.error ? `${err.error.error.code} ${err.error.error.message}` : err.error?.message;
                this.cdRef.detectChanges();
            },
        );
    }

    public showPinLoginForm(): void {
        this.isPinLoginFormVisible = true;
        this.cdRef.detectChanges();
    }

    public hidePinLoginForm(): void {
        this.isPinLoginFormVisible = false;
        this.cdRef.detectChanges();
    }

    checkAndSetActiveMicrosoftAccount() {
        // Check if the account is active
        let activeAccount = this.msalService.instance.getActiveAccount();

        // Check if there is no active account but there are some accounts in the authService
        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            // Get all accounts from the authService
            let accounts = this.msalService.instance.getAllAccounts();

            // Set the first account as the active account
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }

    // Sign in user via a Microsoft account
    doMicrosoftSignIn() {
        let loginRequest = {
            scopes: ['user.read'],
        };
        this.msalService.loginRedirect(loginRequest);
    }

    // Check Azure user identity in the backend
    private async checkMicrosoftUserIndentity() {
        const request = {
            scopes: ['user.read'],
        };
        // Acquire a JWT to access Microsoft Graph and send it to the backend
        this.msalService.instance
            .acquireTokenSilent(request)
            .then(response => {
                // Send accessToken to the check-azure endpoint in the backend
                this.api.post({ url: '/login/check-azure', data: { token: response.accessToken }, skipAuth: true }).subscribe(
                    (res: ApiResponse<any>) => {
                        console.log('res', res.data);
                        this.error = null;
                        this.userService
                            .loginUser(res.data)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(() => {
                                this.userService.reloadRoute(this.redirect || '/');
                            });
                        // Set Azure loading state to false after successful login
                        this.azureLoading = false;
                        this.cdRef.detectChanges();
                    },
                    (err: ApiErrorResponse<any>) => {
                        // Set Azure loading state to false if there is an error
                        this.azureLoading = false;
                        this.error = err.error?.error ? `${err.error.error.code} ${err.error.error.message}` : err.error?.message;
                        this.cdRef.detectChanges();
                    },
                );
            })
            .catch((err: ApiErrorResponse<any>) => {
                // Set Azure loading state to false if there is an error
                this.azureLoading = false;
                this.error = err.error?.error ? `${err.error.error.code} ${err.error.error.message}` : err.error?.message;
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

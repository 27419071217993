import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, Validator } from '@angular/forms';
import { Color } from '@iplab/ngx-color-picker';
import { SfFormView, ValueAccessorBase } from '@hutsix/ngxh6';

@Component({
    selector: 'sf-form-colour-picker',
    templateUrl: './sf-form-colour-picker.component.html',
    styleUrls: ['./sf-form-colour-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SfFormColourPickerComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SfFormColourPickerComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormColourPickerComponent extends ValueAccessorBase implements Validator, OnChanges {
    public type: string = 'text';

    @Input() public view: SfFormView;
    @ViewChild('formInput', { static: true }) formInput: NgModel;

    ngOnChanges(): void {
        this.type = 'text';
        const otherTypes = ['number', 'integer', 'email', 'button', 'hidden', 'password', 'url'];

        otherTypes.forEach(t => {
            if (this.view.vars.block_prefixes.includes(t)) {
                this.type = t;
            }
        });

        if (this.type === 'integer') {
            this.type = 'number';
        }

        this.cdRef.detectChanges();
    }

    colorChange(): void {
        const color = new Color(this.inputValue);
        this.inputValue = color.toHexString();
        this.onChange(this.inputValue);
    }
}

<ng-container *ngIf="!isPinLoginFormVisible; else pinLoginForm">
    <div class="content-container m-0">
        <div class="d-flex my-4">
            <div class="login-form mx-auto">
                <!-- Normal Sign In -->
                <form (ngSubmit)="doSignin()" #signinForm="ngForm">
                    <div class="d-flex mb-3 flex-column">
                        <img class="logo p-4" src="/assets/logo-large.svg" alt="App Logo" />
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="signin_email">Email or Username</label>
                        <input class="form-control" type="text" id="signin_email" name="email" required [(ngModel)]="signin.email" autocomplete="username" />
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="signin_pass">Password</label>
                        <input
                            class="form-control"
                            type="password"
                            id="signin_pass"
                            name="password"
                            required
                            [(ngModel)]="signin.password"
                            autocomplete="current-password"
                        />
                    </div>

                    <div class="d-flex">
                        <input type="submit" value="Sign In" class="btn btn-primary ms-0 mb-4" [disabled]="!signinForm.form.valid || loading" />
                    </div>

                    <!--        <p class="my-0 small"><a routerLink="/password-reset">Forgot Password?</a></p>-->
                </form>

                <hr />

                <!-- Microsoft Sign In -->
                <button (click)="doMicrosoftSignIn()" class="btn btn-outline-primary w-100" type="button" [disabled]="azureLoading">
                    <img src="assets/icons/Microsoft-Logo.svg" alt="Microsoft Logo" class="me-2" style="height: 1em; vertical-align: middle" />
                    Log in with Microsoft
                </button>

                <!-- Pin Sign In -->
                <button (click)="showPinLoginForm()" class="btn btn-outline-primary w-100 mt-2" type="button">Log in with PIN</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #pinLoginForm>
    <div class="content-container m-0">
        <div class="d-flex my-4">
            <div class="login-form mx-auto">
                <form (ngSubmit)="doPinSignin()" #signinForm="ngForm">
                    <div (click)="hidePinLoginForm()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                            />
                        </svg>
                    </div>

                    <div class="d-flex mb-3 flex-column">
                        <img class="logo p-4" src="/assets/logo-large.svg" alt="App Logo" />
                    </div>

                    <div class="alert alert-danger" role="alert" *ngIf="error">Error: {{ error }}</div>

                    <div class="form-group">
                        <label class="form-label" for="pin_signin_email">Email or Username</label>
                        <input
                            class="form-control"
                            type="text"
                            id="pin_signin_email"
                            name="email"
                            required
                            [(ngModel)]="pinSignin.email"
                            autocomplete="username"
                        />
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="signin_pin">PIN</label>
                        <input
                            class="form-control"
                            type="password"
                            id="signin_pin"
                            name="password"
                            required
                            [(ngModel)]="pinSignin.pin"
                            autocomplete="current-password"
                            pattern="[0-9]*"
                            inputmode="numeric"
                        />
                    </div>

                    <div class="d-flex">
                        <input type="submit" value="Sign In" class="btn btn-primary ms-0 mb-4" [disabled]="!signinForm.form.valid || loading" />
                    </div>

                    <!--                        <p class="my-0 small"><a routerLink="/password-reset">Forgot Password?</a></p>-->
                </form>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view" @fadeInOut>
        <sf-form class="invisible position-absolute" name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['contact', 'timestamp']"></sf-form>
        <sf-form name="form2" [view]="form.view" [(ngModel)]="form.model" [fields]="['signatureName', 'updated']"></sf-form>
        <p>In order to provide relevant services Roper Gulf Regional Council (Council) need to collect and record personal information.
            All information is stored securely on a system that only Council staff can access.
        </p>
        <p>
            Council take the confidentiality and security of your information seriously, and will only share your information with other
            parties in the following circumstances:
        </p>

        <ul>
            <li>If you give us permission to share information with another person or organisation;</li>
            <li>If we are worried about your child/youth’s safety or the safety of someone else;</li>
            <li>
                If we are required to disclose information by law, such as a court subpoena or mandatory report (Child Safety or Family and Domestic Violence)
            </li>
        </ul>
        <p>
            If your personal information is to be used in any other way you will be asked for consent and have the right to refuse. If you have any concerns or
            questions about privacy, confidentiality or informed consent, please ask a Council staff member.
        </p>

        <h4>Terms</h4>
        <p>I agree and understand that:</p>

        <div class="terms">
            <ul>
                <li><label>The activity will be supervised.</label></li>
                <li>
                    <label>
                        My child/ward attends the activity entirely at their own risk, and must exercise due care to ensure their personal safety and that of
                        others.
                    </label>
                </li>
                <li><label>My child/ward no known medical or physical condition that may be made worse by participation in the activity.</label></li>
                <li><label>My child/ward must conduct themselves in a safe and responsible manner for the duration of the activity.</label></li>
                <li><label>My child/ward must follow any direction or advice affecting safety given by staff.</label></li>
                <li>
                    <label>
                        I accept all risks associated with the activity for my child/ward and release the Organisation from all claims, actions, suits, and
                        demands from loss or injury to me or my dependents arising from my participation in this activity.
                    </label>
                </li>
                <br>
                <sf-form name="form3" [view]="form.view" [(ngModel)]="form.model" [fields]="['photo', 'caseWork']"></sf-form>
            </ul>
        </div>
        <p>
            Council have a strict no violence, no bullying or disrespectful behaviour.
            If your child/youth behaves poorly Council will ask them to leave and in serious cases follow up with responsible guardian.
        </p>

        <sf-form name="form4" [view]="form.view" [(ngModel)]="form.model" [fields]="['additionalNotes', 'signature']"></sf-form>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            (cancel)="cancel()"
            (delete)="delete()"
            (archive)="archive($event)"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError>
    <ng-container *ngIf="form.error; else loading">
        <ngxh6-api-error [error]="form.error"></ngxh6-api-error>

        <div class="d-flex actions" [class.sticky]="options.sticky_actions">
            <input type="button" value="Back" (click)="location.back()" class="btn btn-gray" *ngIf="options.show_back" [disabled]="form.saving" />
            <input type="button" value="Cancel" (click)="cancel()" class="btn btn-gray" *ngIf="options.show_cancel" [disabled]="form.saving" />
        </div>
    </ng-container>

    <ng-template #loading>
        <ngxh6-loading-spinner @fadeInOut></ngxh6-loading-spinner>
    </ng-template>
</ng-template>

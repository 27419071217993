import { CrudOptions } from '@hutsix/ngxh6';
import cruds_conf from '../_generated/ext_config/cruds.json';

/** Override generated CRUD values here */
const cruds_conf_overrides: Override = {
  attachments: {
    tableSpec: {
      columns: [
        {
          header: 'File',
          type: 'simple',
          value: 'label',
        },
        {
          header: 'Added By',
          type: 'objvalue',
          typeOption: 'string',
          value: 'user',
        },
        {
          header: 'Date',
          type: 'date',
          value: 'created',
        },
      ],
    },
  },
  goal_specs: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'name',
        },
      ],
    },
  },
  goals: {
    tableSpec: {
      columns: [
        {
          header: 'Goal',
          type: 'simple',
          value: 'string',
        },
        {
          header: 'Detail',
          type: 'simple',
          value: 'detail',
          class: 'col-4 preserve-whitespace',
        },
        {
          header: 'Status',
          type: 'simple',
          value: 'statusLabel',
        },
      ],
    },
  },
  hobby_specs: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'name',
        },
      ],
    },
  },
  hobbies: {
    tableSpec: {
      columns: [
        {
          header: 'Hobby',
          type: 'simple',
          value: 'string',
        },
        {
          header: 'Detail',
          type: 'simple',
          value: 'detail',
          class: 'col-4 preserve-whitespace',
        },
      ],
    },
  },
  referral_sources: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'name',
        },
      ],
    },
  },
  facilities: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'name',
        },
      ],
    },
  },
  referrals: {
    tableSpec: {
      columns: [
        {
          header: 'Referral Source',
          type: 'objvalue',
          typeOption: 'string',
          value: 'referralSource',
        },
        {
          header: 'Communication',
          type: 'simple',
          value: 'communication',
          class: 'ucase-1st-letter',
        },
        {
          header: 'Detail',
          type: 'simple',
          value: 'detail',
          class: 'col-4 preserve-whitespace',
        },
        {
          header: 'Mandatory Report',
          type: 'boolean',
          value: 'mandatoryReport',
          class: 'col-1 text-center',
        },
        {
          header: 'Status',
          type: 'simple',
          value: 'statusLabel',
          class: 'col-2',
        },
        {
          header: 'Date',
          type: 'date',
          value: 'date',
        },
      ],
    },
  },
  individual_events: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'nested',
          typeOption: ['0', 'contact', 'fullName'],
          value: 'attendance',
        },
        {
          header: 'Date',
          type: 'date',
          typeOption: 'mediumDate',
          value: 'date',
        },
        {
          header: 'Start',
          type: 'time',
          value: 'startTime',
        },
        {
          header: 'End',
          type: 'time',
          value: 'endTime',
        },
        {
          header: 'Duration',
          type: 'timedifference',
          typeOption: 'startTime',
          value: 'endTime',
        },
        {
          header: 'Community',
          type: 'objvalue',
          typeOption: 'name',
          value: 'community',
        },
        {
          header: 'Staff',
          type: 'usericon',
          typeOption: 'small',
          value: 'staff',
        },
      ],
    },
  },
  calendar_events: {
    tableSpec: {
      columns: [
        {
          header: 'Start',
          type: 'date',
          typeOption: 'mediumDate',
          value: 'start',
          class: 'col-2',
        },
        {
          header: 'End',
          type: 'date',
          typeOption: 'mediumDate',
          value: 'end',
          class: 'col-2',
        },
        {
          header: 'Event',
          type: 'simple',
          value: 'title',
        },
        {
          header: 'Communities',
          type: 'arraypluck',
          typeOption: 'name',
          value: 'communities',
        },
      ],
    },
  },
};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  // test: new CrudOptions({
  //   objectLabel: 'Test',
  //   apiEndpoint: '/api/v1/examples',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}

type Override = { [Property in keyof Partial<typeof cruds_conf>]: Partial<CrudOptions> };
